@font-face {
    font-family: 'proxima_nova_bold';
    src: url('../fonts/proxima/proxima_nova_bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/proxima_nova_bold.woff2') format('woff2'),
         url('../fonts/proxima/proxima_nova_bold.woff') format('woff'),
         url('../fonts/proxima/proxima_nova_bold.ttf') format('truetype');
}

@font-face {
    font-family: 'proxima_nova_regular';
    src: url('../fonts/proxima/proxima_nova_regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/proxima_nova_regular.woff2') format('woff2'),
         url('../fonts/proxima/proxima_nova_regular.woff') format('woff'),
         url('../fonts/proxima/proxima_nova_regular.ttf') format('truetype');
}

@font-face {
    font-family: 'proxima_nova_light';
    src: url('../fonts/proxima/proxima_nova_light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/proxima_nova_light.woff2') format('woff2'),
         url('../fonts/proxima/proxima_nova_light.woff') format('woff'),
         url('../fonts/proxima/proxima_nova_light.ttf') format('truetype');
}

@font-face {
    font-family: 'proxima_nova_thin';
    src: url('../fonts/proxima/proxima_nova_thin.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/proxima_nova_thin.woff2') format('woff2'),
         url('../fonts/proxima/proxima_nova_thin.woff') format('woff'),
         url('../fonts/proxima/proxima_nova_thin.ttf') format('truetype');
}

@font-face {
    font-family: 'foundation-icons';
    src: url(../fonts/icons/foundation-icons.eot?#iefix) format("embedded-opentype"),
         url(../fonts/icons/foundation-icons.woff) format("woff"),
         url(../fonts/icons/foundation-icons.ttf) format("truetype"),
         url(../fonts/icons/foundation-icons.svg#fontcustom) format("svg");
}

body {
    margin: 0;
    padding: 0;
}

html { height: 100%; }

body {
    font-family: 'proxima_nova_regular', "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #F4F6F9;
}

.global-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main {
    padding: 2rem;
    margin-top: 60px;
    display: flex;
    -o-justify-content: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.enabled { color: green; }

.red,
.disabled { color: red; }


a { color: #0366d6; }

code { color: #E01A76; }

.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    -ms-border-radius: 4px;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.btn.primary {
    color: #fff;
    background-color: #1965B1;
    border-color: #16589b;
}

.btn.btn-cancel {
    background-color: #F0F0F0;
    color: #1e355c;
    border: 1px solid #F0F0F0;
    text-shadow: none;
}

.btn.btn-cancel:hover {
    background-color: #E0E0E0;
    border: 1px solid #E0E0E0;
}

.full-width { width: 100%; }

.centered.vertical {
    -ms-transform: translate(-50%, 50%);
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
}

.invalid {
    color: #a51818;
    border-color: #a51818;
}

.text-danger {
    color: #a51818;
}

.header-logo {
    -o-justify-content: flex-start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    height: 50px;
    margin-left: 10px;
}

.header-righthand-menu {
    padding-right: 20px;
    padding-top: 10px;
}

.large-icon { font-size: 30px; }

.login-form ul { padding-inline-start: 20px; }

    .login-form ul li {
        margin-bottom: .6rem;
    }

.login-form .login-form-row { padding-top: 10px; }

    .login-form .login-form-row .form-control-label input[type="checkbox"] {
        margin-right: 5px;
        position: relative;
        top: 1px;
    }

.version-overlay {
    position: fixed;
    display: block;
    right: 0;
    bottom: 0;
    z-index: 9001;
    font-size: smaller;
    opacity: .35;
    pointer-events: none;
}

.header-main {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #1965B1;
    height: 60px;
    color: #fff
}

    .header-main .header-title,
    .header-main .header-language > .languageSwitch {
        font-weight: Bold;
        font-family: proxima_nova_thin;
        font-size: 16pt;
        text-align: center;
        line-height: 60px;
        text-shadow: none;
        text-decoration: none;
        color: #fff
    }

    .header-main .header-language {
        position: fixed;
        right: 0;
        display: flex;
    }

        .header-main .header-language > .languageSwitch {
            background: none;
            border: none;
            height: 60px;
            width: 60px;
        }

            .header-main .header-language > .languageSwitch:active,
            .header-main .header-language > .languageSwitch:focus {
                outline: none;
            }

@media screen and (max-width: 500px) {
    .header-main { display: flex; }

    .header-main .header-title {
        position: unset;
        left: unset;
        transform: unset;

        padding-left: 15px;
        margin-right: auto;
    }

    .header-main .header-language { margin-left: auto; }
}

.content-container {
    display: flex;
    background-color: #fff;
    -o-justify-content: center;
    -webkit-justify-content: center;
    justify-content: center;
    border: solid 1px #e4e7eb;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    padding: 20px 30px 20px;
    max-width: 600px;
    width: 600px;
    width: -moz-calc(100% - 40px);
    width: -webkit-calc(100% - 40px);
    width: calc(100% - 40px);
}



.large-icon {
    width: auto;
    padding-right: 20px;
}