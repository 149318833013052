.video-container {
    position: fixed;
    top: 0;
    left: 0;
    background-color:#000000;
}

.feed-aside {
    position: absolute;
    height: 15vh;
    border: solid #757373 1px;
}

    .feed-aside.user {
        -moz-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        transform: scale(-1, 1);
    }

.feed-not-available {
    position: absolute;
    background-color: white;
    padding-top: 5px;
    width: 100px;
}   

.feed-not-available img {
    height: 50px;
}

.feed-section {
    width:100%;
    height:100%
}

.feed-peer {
    object-fit: cover;
    z-index: -100;
}

.feed-message {
    background-color: black;
    color: white;
    font-weight: bold;
    width: 100%;
    text-align: center;
    top: 50%;
    position: absolute;
}

.ui-controls {
    position: fixed;
    background-color: #111; /*rgba(255, 0, 0, 0.5);*/
    z-index: 2;
    bottom: 0;
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    align-items:center;
}

.video-overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: #111;
}


button {
    outline: none;
    color: white;
    display: block;
    opacity: 1;
    background: transparent;
    border: solid 2px #fff;
    padding: 0;
    text-shadow: 0 0 4px black;
    background-position: center center;
    background-repeat: no-repeat;
    pointer-events: auto;
    z-index: 2;
    background-color: #fff;
}


.uiButton {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #fff;
}

.uiButton.hang-up-button {
    background-color: #FF0000;
    border: 0;
}

@media screen and (orientation: portrait) {

    .video-container {
        width: 100%;
        height: 90%;
    }

    .ui-controls {
        width: 100%;
        height: 10%;
        min-height: 50px;
        left: 0;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .uiButton {
        width: 48px;
        height: 48px;
    }
}

@media screen and (orientation: landscape) {
    .video-container {
        width: 90%;
        height: 100%;
    }

    .ui-controls {
        width: 10%;
        min-width: 50px;
        height: 100%;
        right: 0;
    }

    .ui-controls.left {
        left: 0;
    }

    .switchCameraButton {
        left: calc(50% - 32px);
        top: calc(18% - 32px);
    }

    .toggleFullScreenButton {
        left: calc(50% - 32px);
        top: calc(82% - 32px);
    }
}

.debug-info {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #ffb2b2;
    border: dashed thin black;
}

.debug {

    position: absolute;
    bottom:0;
    left:0;
    height:400px;
    width:300px;
    background-color:#e1dada;
    overflow:scroll;

}